import Service from "../../Service";

const resource = "sampledamagecultive/"

export default {
    pagination(dt, requestID) {
        return Service.post(resource + "pagination", dt, {
            params: { requestID: requestID },
        });
    },
    list(TypeCultive, requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID: requestID,TypeCultive:TypeCultive },
        });
    },
    save(alh, requestID) {
        return Service.post(resource + "save", alh, {
            params: { requestID }
        });
    }
}
<template>
  <v-container>
    <v-row>
      <v-col lg="8">
        <s-crud
          @getDataRefresh="getdata($event)"
          add
          edit
          remove
          @save="save($event)"
          title="Daños por Cultivo"
          :config="config"
          :filter="filter"
          no-full
        >
          <template v-slot:filter>
            <v-container>
              <v-row justify="center">
                <v-col lg="4"
                  ><s-select-definition
                    v-model="filter.TypeDamage"
                    :def="1265"
                    label="Tipo Daño"
                  ></s-select-definition>
                </v-col>
                <v-col lg="3">
                  <s-select-definition
                    v-model="filter.TypeCultive"
                    :def="1173"
                    label="Tipo Cultivo"
                  ></s-select-definition>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template scope="props">
            <v-container>
              <v-row justify="center">
                <v-col lg="4" class="pb-0">
                  <s-text
                    label="Descripción"
                    autofocus
                    v-model="props.item.SdpDescription"
                  ></s-text>
                </v-col>
                <v-col lg="4" class="pb-0">
                  <s-text
                    label="Abreviatura"
                    v-model="props.item.SdpAbbreviation"
                  ></s-text>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col lg="4" class="pb-0">
                  <s-select-definition
                    v-model="props.item.TypeDamage"
                    :def="1265"
                    label="Tipo Daño"
                  ></s-select-definition>
                </v-col>
                <v-col lg="4" class="pb-0">
                  <s-select-definition
                    v-model="props.item.TypeCultive"
                    :def="1173"
                    label="Tipo Cultivo"
                  ></s-select-definition>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </s-crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _sSampleDamage from "@/services/FreshProduction/SampleDamage/SampleDamageCultive";

export default {
  components: {},
  data: () => ({
    filter: {
      TypeCultive: null,
    },
    config: {
      service: _sSampleDamage,
      model: {
        SdpID: "ID",
      },
      headers: [
        { text: "ID", value: "SdpID", width: 50 },
        { text: "Descripción", value: "SdpDescription" },
        { text: "Abreviatura", value: "SdpAbbreviation", width: 100 },
        { text: "Tipo Daño", value: "TypeDamageName", width: 150 },
        { text: "Tipo Cultivo", value: "TypeCultiveName", width: 100 },
      ],
    },
  }),
  methods: {
    getdata(i) {
      console.log(i);
    },
    
    save(item) {
      if (item.SdpDescription.trim() == "") {
        this.$fun.alert("Falta Descripción", "warning");
        return;
      }
      if (item.SdpAbbreviation.trim() == "") {
        this.$fun.alert("Falta Abreviatura", "warning");
        return;
      }
      item.SecStatus = 1;
      item.SdpStatus = 1;
      item.save();  
    },
  },
};
</script>
